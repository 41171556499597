import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/the-baby-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1973 shocker, The Baby"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 16</h1>
                    <h2>The Baby</h2>
                    <h3>September 4, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <p><b>Director:</b> Ted Post // <b>Starring:</b> Ruth Roman &amp; Anjanette Comer</p>
                        <div className="player">
                        <iframe src="https://podcasters.spotify.com/pod/show/bring-me-the-axe/embed/episodes/16-The-Baby-e28so3k" height="102px" width="400px" frameborder="0" scrolling="no" title="Bring Me The Axe! horror podcast episode 16, The Baby"></iframe>
                        </div>
                        <p><Link to="/transcripts/the-baby">Read the episode transcript</Link></p>
                        <p>At long last, Bryan and Dave kick off their What The Fuck??? series with Ted Posts deeply troubling, alienating, and off-putting thriller, The Baby, from 1973. Dave thinks it doesn't make any sense. Bryan disagrees strongly, declaring it one of his all-time favorite movies. The Baby is unpredictable at every turn and shocks all the way to the end.At long last, Bryan and Dave kick off their What The Fuck??? series with Ted Posts deeply troubling, alienating, and off-putting thriller, The Baby, from 1973. Dave thinks it doesn't make any sense. Bryan disagrees strongly, declaring it one of his all-time favorite movies. The Baby is unpredictable at every turn and shocks all the way to the end.</p>
                        <p>Trailer: <OutboundLink href="https://www.youtube.com/watch?v=vITUjvwnavc" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=vITUjvwnavc</OutboundLink></p>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/burnt-offerings">Next Episode</Link></li>
                        <li><Link to="/episodes/silver-bullet">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)